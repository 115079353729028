<template>
  <!-- <main-wrapper tool-background-image="linear-gradient(135deg, #C3BEF0 0%, #DEFCF9 100%)" border-left-color="#C3BEF0">
    <template slot="tool">
      <span class="sub-title ipx-1">电子围栏</span>
    </template> -->
    <div class="v-wrapper tool--none">
      <div class="v-nav">
        <div class="v-area v-nav-road">
          <!-- <p class="v-area__title">围栏列表</p> -->
          <p class="imt-3 imb-0 imx-3">
            <!-- `<span class="high--text">${regionList.length}</span>` -->
            {{$t('regionManage.totalRoadTip', {count: regionList.length})}}
            <el-checkbox v-model="containChild" style="float:right;">{{$t('regionManage.includeChild')}}</el-checkbox>
          </p>

          <div class="v-split-h imt-3 imb-0 imx-0"></div>
          <div class="imt-0 imx-0">
            <el-input class="risk-polygon-query" :placeholder="$t('onlineMonitor.favoriteRoadTip')" v-model="queryKey" clearable></el-input>
            <!-- <el-autocomplete
              class="risk-polygon-query" size="small"
              :placeholder="$t('onlineMonitor.favoriteRoadTip')" v-model="queryKey" clearable
              :fetch-suggestions="querySearchAsync"
              @select="handleSelectRegion"
            ></el-autocomplete> -->
          </div>
          <div class="region imy-0" :class="{'active': activeRegionId == row.PolygonID}" v-for="row in tableList" :key="row.PolygonID" @click="() => rowClick(row)">
            <div class="content">
              <p class="region__name" :title="$t('regionManage.bindCountTip', {count: row.DeviceCount})">{{row.PolygonName}}[{{row.PolygonID}}]</p>
              <p class="region__desc" v-if="false">已绑定{{row.DeviceCount}}台设备</p>
            </div>
            <div class="option" @click.stop>
              <i-drop-down trigger="click" :show-text="$t('common.operate')">
                <i class="el-icon-more-outline" style="font-size:14px;width:16px;margin-top:5px;" slot="active"></i>
                <i-drop-down-option :label="$t('regionManage.modify')" value="0" @click="modifyRegion(row)"></i-drop-down-option>
                <i-drop-down-option :label="$t('regionManage.deflete')" value="1" @click="deleteRegion(row)"></i-drop-down-option>
                <i-drop-down-option :label="$t('regionManage.vehiceBindList')" value="3" @click="openDialogList(row)"></i-drop-down-option>
                <i-drop-down-option :label="$t('regionManage.contactRegion')" value="3" @click="openRegionObject(row)"></i-drop-down-option>
                <i-drop-down-option :label="$t('regionManage.cancelContact')" value="4" @click="openDialogList(row)"></i-drop-down-option>
              </i-drop-down>
            </div>
          </div>

          <el-pagination
            class="v-pager"
            v-if="regionList.length > 8 && regionList.length != 0"
            background
            layout="prev, jumper, next"
            :current-page.sync="table.pageIndex"
            :page-size="table.pageSize"
            :total="regionList.length">
          </el-pagination>
          <p v-if="regionList.length <=8 && regionList.length != 0" class="imy-1 caption" style="text-align:center;color: #B0BEC5;">{{$t('common.noMoreResults')}}</p>
          <div class="imx-0 imt-0 imb-0" style="position: relative;">
            <el-button type="primary" class="border" plain style="width: 100%;border-radius:20px;" @click="addRegion">{{$t('regionManage.addRoad')}}</el-button>
          </div>
        </div>
        <!-- <div class="v-area v-nav-road">
          <p class="v-area__title"></p>

        </div> -->
      </div>
      <div class="v-body" ref="container"></div>
      <select-car style="position:absolute;left: 274px;top:24px;z-index:10;" @select="carSelect"></select-car>
      <!--区域车辆-->
      <transition name="fade-polygon" >
      <div class="fixedTop" v-if="showBindObject" :key="activePolygon.PolygonID">
        <div class="fixedTopHeader">
          {{activePolygon.PolygonName}}
          <span class="fixedTopHeaderClose">
            <i class="el-icon-refresh" @click="refreshBindObject"></i>
            <i class="el-icon-close" @click="showBindObject = false"></i>
          </span>
        </div>
        <div class="vehicle-split"></div>
        <ul v-if="activePolygon.list.length">
          <li v-for="(item,index) in activePolygon.list.slice(0, 10)" :key="index">
            <span>
              {{item.VehicleName}}
              <!-- <span class="IOType">{{$t('regionManage.ioTypeList')[item.IOType] || '--'}}</span> -->
            </span>
            <p class="SIM2">
              {{item.SIM2}}
              <span class="Speed" v-if="item.CheckSpeed">({{$t('regionManage.limitTip2', {speed: item.MaxSpeed})}})</span>
            </p>
          </li>
        </ul>
        <p class="nodata" v-else>
          {{$t('regionManage.nodata')}}
        </p>
        <p class="viewAll" v-if="activePolygon.list.length" @click="openDialogList(activePolygon)">
          {{$t('regionManage.viewAll')}}
        </p>
      </div>
      </transition>

      <el-dialog @closed="resetDialog" append-to-body :title="dialogTitle" :visible.sync="dialogVisible" custom-class="v-dialog v-dialog--lg v-dialog--mdpadding v-dialog--footer">
        <el-tabs v-model="activeName" @tab-click="tabClick">
          <el-tab-pane :label="$t('regionManage.roadSetting')" name="first">
            <div class="tab-wrapper">
              <div class="bdContent" ref="tabContainer" style="width:100%;height:100%"></div>
              <div class="v-tool" :class="{'minimize':isMinimize}">
                <span class="tip"> {{ (!!modifyRow && !isMinimize) ? $t('regionManage.clearTip') : $t('regionManage.addTip')}}
                  <transition name="scale-shrink">
                    <div v-if="isDrawEnd" class="flat-btn" style="padding: 0 4px">
                      <el-button @click="modifyDraw" class="border" type="primary" plain>{{ $t('regionManage.editPoly')}}</el-button>
                      <el-button @click="startDraw" class="border" type="primary" plain>{{ $t('regionManage.drawAgain')}}</el-button>
                    </div>
                    <div class="flat-btn ipx-0" style="padding: 0 4px" v-else>
                      <el-button v-if="isEditing" @click="endEdit" class="border" type="primary" plain>{{ $t('regionManage.endEdit')}}</el-button>
                    </div>
                  </transition>
                </span>
                <p class="start" v-if="!modifyRow" @click="startDraw">
                  <span>{{$t('regionManage.start')}}</span>
                </p>
                <template v-else>
                  <p class="start">
                    <span @click="startDraw">{{$t('regionManage.drawAgain')}}</span>
                    <span @click="modifyDraw" class="iml-5">{{$t('regionManage.editPoly')}}</span>
                  </p>
                </template>
              </div>
              <div class="tab-place-search"> 
                <template v-if="currentMap==='Bmap'">
                  <map-place @select="(lng,lat) => tabMap.centerAndZoom(new BMap.Point(lng, lat),17)" />
                </template>
                <template v-else>
                    <map-place @select="(lng,lat) => tabMap.setZoomAndCenter(17, [lng,lat])" v-if="currentMap!=='Google'"/>
                </template>
              </div>
              <select-car style="position:absolute;right:24px;top:50px;z-index:10;" @select="carSelectTab"></select-car>
            </div>

          </el-tab-pane>
          <el-tab-pane :label="$t('regionManage.contactRegion')" name="second">
          <div class="tab-wrapper tab--flex">
            <div class="tabPanels">
              <div class="cols step1">
                  <tree-user
                    v-if="dialogVisible"
                    :title="$t('regionManage.selectVehicle')"
                    show-icon
                    show-vehicle
                    :widthFixed ='false'
                    :show-object-count.sync="showObjectCount"
                    :use-local-setting="false"
                    @check="treeCheck"
                  ></tree-user>
              </div>
              <div class="cols step2">
                <div class="searchInput">
                  <SelectDevice @select="handleQuery" :clear="searchCheck.VehicleName"></SelectDevice>
                  <div class="group"><el-tag size="mini" v-if="searchCheck" clsss="tag" closable @close="clearSearch" >{{searchCheck.VehicleName}}</el-tag></div>
                </div>
                <el-form ref="form" label-width="100px" label-position='top' size="small">
                  <div class="solid">
                    <el-form-item :label="$t('regionManage.roadName')">
                        <!-- <span class="label-form">{{$t('regionManage.regionName')}}</span> -->
                      <el-select v-model="form.regionId" :disabled="!!modifyRow">
                          <el-option v-for="item in regionList" :key="item.PolygonID" :label="item.PolygonName" :value="item.PolygonID"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('regionManage.validTime')">
                        <div class="elRow">
                          <div class="elCol">
                            <el-time-picker
                              is-range
                              class="v-range"
                              v-model="form.timeRange"
                              range-separator="-"
                              :placeholder="$t('common.selectDate')"
                              :clearable="false">
                            </el-time-picker>
                          </div>
                          <div class="elCol">
                              <!-- <el-time-picker
                                  class="v-range"
                                  v-model="form.timeRange[1]"
                                  :placeholder="$t('common.selectDate')"
                                  :clearable="false">
                                </el-time-picker> -->
                          </div>
                        </div>
                      </el-form-item>
                      <el-form-item :label="$t('regionManage.roadWidth')">
                        <el-input-number v-model="form.RoadWidth" :min="0" :max="999"><template slot="append">米</template></el-input-number>
                        <p>（{{$t('regionManage.unit1')}}）</p>
                      </el-form-item>

                      

            <!-- <el-form-item label-position='left'>
                  <div class="line">
                      <span @click="()=>{form.needAlarm =!form.needAlarm}">
                          <i-icon name="iconxuanzhong" v-if="form.needAlarm"></i-icon><i
                              v-else></i>
                      </span>
                      <p>{{$t('regionManage.triggerAlarm')}}</p>
                  </div>
              </el-form-item> -->
                      <el-form-item label-position='left'>
                          <div class="line"><span @click="()=>{form.checkSpeed =!form.checkSpeed}">
                                  <i-icon name="iconxuanzhong" v-if="form.checkSpeed"></i-icon><i
                                      v-else></i>
                              </span>
                              <p>{{$t('regionManage.regionLimit')}}</p>
                              <div class="input">
                                  <el-input v-model="form.maxSpeed" :disabled="!form.checkSpeed"
                                      size="mini" :min="1" :max="999" :controls="false">
                                      <template slot="append">{{$t('regionManage.limitTip')}}</template>
                                  </el-input>
                              </div>
                          </div>
                      </el-form-item>
                  </div>
                  <div class="v_text i-link" style="color: #409EFF;" @click="() => openDialogList({PolygonID:form.regionId})">{{$t('regionManage.viewBindDevice')}}</div>
                </el-form>
              </div>
            </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div slot="footer" class="dialog-footer">
          <el-button class="border" plain type="primary" @click="dialogVisible = false">{{$t('common.close')}}</el-button>
          <el-button class="border" plain v-if="activeName=='first'" type="primary" @click="saveAndNext" :loading="btnLoading" :disabled="!isDrawEnd">{{$t('common.next')}}</el-button>
          <el-button class="border" plain v-else type="primary" @click="addRegionObject" :loading="btnLoading" >{{$t('common.save')}}</el-button>
        </div>
      </el-dialog>
      <el-dialog append-to-body custom-class="v-dialog v-dialog--md v-dialog--mdpadding v-dialog--footer" :visible.sync="dialogListVisible" :title="$t('regionManage.vehiceBindList')">
        <el-table style="width: 100%" :data="dialogList">
          <el-table-column :label="$t('regionManage.vehicleName')" prop="VehicleName" width="120">
            <p slot-scope="{row}">
              {{row.VehicleName || row.SIM}}
            </p>
          </el-table-column>
          <el-table-column :label="$t('regionManage.deviceID')" prop="SIM2" width="130"></el-table-column>
          <el-table-column :label="$t('regionManage.lineType')" width="115">
            <template slot-scope="{row}">
              <span style="color:#3F51B5;">{{row.TrackerType==0?$t('dict')['有线设备']:$t('dict')['无线设备']}}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('regionManage.roadWidth')" width="100">
            <template slot-scope="{row}" >
              <p >{{row.IOType}} m</p>
            </template>
          </el-table-column>
          <el-table-column :label="$t('regionManage.validTime')" width="160">
            <template slot-scope="{row}">
              <p><span style="color:#3F51B5">{{row.StartTime}}-{{row.EndTime}}</span></p>
            </template>
          </el-table-column>
          <el-table-column :label="$t('regionManage.roadLimit')" width="200">
            <template slot-scope="{row}">
              <p v-if="row.MaxSpeed > 0">{{$t('regionManage.yes')}} / <span style="color:#f44336">{{$t('regionManage.limitTip2', {speed: row.MaxSpeed})}}</span></p>
              <p v-else>{{$t('regionManage.no')}}</p>
            </template>
          </el-table-column>
          <el-table-column min-width="65">
            <p slot-scope="{row}" class="i-link" @click="deleteRegionObject(dialogListRegionId, row.ObjectID)">{{$t('regionManage.jiechu')}}</p>
          </el-table-column>
        </el-table>
        <div slot="footer">
          <el-button @click="dialogListVisible=false">{{$t('common.close')}}</el-button>
        </div>
      </el-dialog>
    </div>

  <!-- </main-wrapper> -->
</template>

<script>

import * as API from '@/api/road'
import IDropDown from '@/base/i-dropdown/i-dropdown'
import IDropDownOption from '@/base/i-dropdown/i-dropdown-option'
import { deleteFromArray } from '@/common/utils'
import { ConvertFrom, GetObjectIdByMixID } from '@/api/common'
import TreeUser from '@/views/common/tree-user'
import miment from 'miment'
import mixinMapCachePosition from '@/views/mixins/mixin-map-cache-position'
// import mixinMapConfig from '@/views/mixins/mixin-map-config'
import {debounce,remoteLoad} from '@/common/utils'
import MapPlace from '@/views/common/map-place'
import SelectCar from '@/views/common/select-car'
import { mapGetters } from 'vuex'
import {GetWrapper, GetTextView} from '@/views/online/google-map.js'
import SelectDevice from '@/views/common/select-device'

export default {
  name: 'riskRoad',
  data() {
    return {
      queryKey: '',
      activeRegionId: null,
      modifyRow: null,
      containChild: false,
      regionList: [],
      dialogVisible: false,
      dialogListRegionId: null,
      dialogListVisible: false,
      dialogList: [],
      activeName: 'first',
      isMinimize: false,
      isDrawEnd: false,
      isEditing: false,
      btnLoading: false,
      treeCheckedKeys: [],
      table: {
        pageSize: 8,
        pageIndex: 1
      },
      form: {
        name: '',
        regionId: null,
        IOType: 0,
        needAlarm: true,
        checkSpeed: false,
        maxSpeed: 0,
        timeRange: [miment('2018/11/11 00:00:00'), miment('2018/11/11 23:59:59')],
        inTips: '',
        outTips: '',
        RoadWidth: 10,
        LimitSpeed: 0
      },
      showObjectCount: false,
      showBindObject: false,
      activePolygon: {
        PolygonID: 0,
        PolygonName: '',
        list: []
      },
      searchState:'',
      searchCheck:''
    }
  },
  computed: {
    tableList() {
      let list = this.regionList.slice(this.table.pageSize*this.table.pageIndex - this.table.pageSize, this.table.pageSize*this.table.pageIndex)
      return list.sort((a, b) => new Date(b.CreateDate).getTime() - new Date(a.CreateDate).getTime())
    },
    dialogTitle() {
      return this.modifyRow ? (this.$t('regionManage.modifyRoad') + `【${this.modifyRow.PolygonName}】`) : this.$t('regionManage.addRoad')
    },
     ...mapGetters([
      'currentMap'
    ])
  },
  components: {
    IDropDown,
    IDropDownOption,
    TreeUser,
    MapPlace,
    SelectCar,
    SelectDevice
  },
  watch: {
    containChild() {
      this.fetchRegionList()
    },
    dialogVisible(val) {
      if (val) {
        setTimeout(() => {
          this.initTabMap()
          if(window.google){

          }else{
            this.isMinimize && this.startDraw()
          }
          if (this.modifyRow) {
            this.isMinimize = false
            this.isDrawEnd = false
            this.isEditing = false
            this.addRegionToMap(this.modifyRow, this.tabMap)
          }
          if (this.mousetool) {
            if(window.google){
              this.polygon.setEditable(false)
            }else{
              this.mousetool.close()
            }
          }
          if (this.msTool) {
            this.msTool.close()
          }
        }, 300)
      } else {
        if (this.mousetool) {
          if(window.google){
            this.polygon.setEditable(false)
          }else{
            this.mousetool.close()
          }
        }
        if (this.msTool) {
          this.msTool.close()
        }
      }
    },
    queryKey() {
      this.d_queryHandle()
    },
    currentMap(a, b) {
        this.defindMap = a
        if (!a&& a === b) {
          return false
        }
        
        window.location.reload()
        this.initMap(a)
    },
  },
  created() {
    this.fetchRegionList()
    this.IOTypeList = this.$t('regionManage.ioTypeList')
    this.d_queryHandle = debounce(this.fetchRegionList, 300)
    this.initMap(this.currentMap)
  },
  mounted() {
    this.initMap(this.currentMap)
  },
  methods: {
    // async initMap(center = [116.397428, 39.90923], zoom = 11) {
    //   const position = this.loadPosition() // mixin
    //   center = position.center
    //   zoom = position.zoom

    //   if (!window.AMap) {
    //     await remoteLoad(this.$AMAP_URL)
    //   }

    //   if (!window.AMapUI) {
    //     await remoteLoad('//webapi.amap.com/ui/1.0/main.js?v=1.0.11')
    //   }

    //   if (!this.SimpleMaker) {
    //     await this.initSimpleMarker()
    //   }

    //   let map = new window.AMap.Map(this.$refs.container, {
    //     resizeEnable: true,
    //     zoom,
    //     center
    //   })

    //   this.map = map
    //   this.AMap = window.AMap
    //   this.AMapUI = window.AMapUI
    //   this.ifNotExistsWindow()
    //   // await this.initLayer()
    //   // await this.initRealScene()
    // },
    initMap(defindMap, center = [116.397428, 39.90923], zoom = 11){
          return new Promise(async (resolve) => {
            const position = this.loadPosition() // mixin
            center = position.center
            zoom = position.zoom
           
            if (defindMap === 'Amap') {
              // if (!window.AMap) {
              //   await remoteLoad(this.$AMAP_URL)
              // }
              // if (!window.AMapUI) {
              //   await remoteLoad('//webapi.amap.com/ui/1.0/main.js?v=1.0.11')
              // }
              await remoteLoad(this.$AMAP_URL)
              await remoteLoad('//webapi.amap.com/ui/1.0/main.js?v=1.0.11')
              if (!this.SimpleMaker) {
                await this.initSimpleMarker()
              }
              let map = new window.AMap.Map(this.$refs.container, {
                resizeEnable: true,
                zoom,
                zooms: [3, 20],
                expandZoomRange: true,
                center
              })
              this.map = map
              this.AMap = window.AMap
              this.AMapUI = window.AMapUI
              this.ifNotExistsWindow()
              // await this.initLayer()
              // await this.initRealScene()
            } else if(defindMap === 'Bmap'){
              // if (!window.BMap) {
              //   window.BMap_loadScriptTime = (new Date).getTime()
              //   window.BMAP_PROTOCOL = window.location.protocol.replace(':', '')
              //   await remoteLoad(this.$BMAP_URL)
              // }
                window.BMap_loadScriptTime = (new Date).getTime()
                window.BMAP_PROTOCOL = window.location.protocol.replace(':', '')
                await remoteLoad(this.$BMAP_URL)

              // if (!window.BMapLib) {
              //   await remoteLoad('//api.map.baidu.com/library/DistanceTool/1.2/src/DistanceTool_min.js')
              //   await remoteLoad('//api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.js')
              //   await remoteLoad('//api.map.baidu.com/library/MarkerClusterer/1.2/src/MarkerClusterer_min.js')
              //   await remoteLoad('//api.map.baidu.com/library/TextIconOverlay/1.2/src/TextIconOverlay_min.js')
              // }

              await remoteLoad('//api.map.baidu.com/library/DistanceTool/1.2/src/DistanceTool_min.js')
              await remoteLoad('//api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.js')
              await remoteLoad('//api.map.baidu.com/library/MarkerClusterer/1.2/src/MarkerClusterer_min.js')
              await remoteLoad('//api.map.baidu.com/library/TextIconOverlay/1.2/src/TextIconOverlay_min.js')
              let map2 = new window.BMap.Map(this.$refs.container, { enableMapClick: false })
              map2.centerAndZoom(new window.BMap.Point(center[0], center[1]), zoom)
              map2.enableScrollWheelZoom(true)
              map2.enableContinuousZoom()
              map2.clearHotspots()

              let mapType = new window.BMap.MapTypeControl(
                {
                  mapTypes: [window.BMAP_NORMAL_MAP, window.BMAP_HYBRID_MAP],
                  anchor: window.BMAP_ANCHOR_TOP_RIGHT
                }
              )
              map2.addControl(mapType)

              this.map = map2
              this.BMap = window.BMap

              // 清空地图
              this.BMap.Map.prototype.clearMap = this.BMap.Map.prototype.clearOverlays
              this.BMap.Map.prototype.on = this.BMap.Map.prototype.addEventListener
              this.BMap.Map.prototype.off = this.BMap.Map.prototype.removeEventListener
              // 关闭信息窗体
              this.BMap.Map.prototype.clearInfoWindow = this.BMap.Map.prototype.closeInfoWindow
              // 打开信息窗体
              this.BMap.InfoWindow.prototype.open = function (m, p) {
                p = p instanceof Array ? new window.BMap.Point(p[0], p[1]) : p
                m.openInfoWindow(this, p)
              }
              // 关闭信息窗体
              this.BMap.InfoWindow.prototype.close = () => this.map.closeInfoWindow()
              // 信息窗体设置位置
              this.BMap.InfoWindow.prototype.setPosition = function (p) {
                p = p instanceof Array ? new window.BMap.Point(p[0], p[1]) : p
                this.isOpen() && map.openInfoWindow(this, p)
              }
              this.BMap.InfoWindow.prototype.getIsOpen = this.BMap.InfoWindow.prototype.isOpen
              // Overlay属性
              this.BMap.Overlay.prototype.setMap = function(m) { m ? m.addOverlay(this) : (this.getMap() && this.getMap().removeOverlay(this))}
              this.BMap.Overlay.prototype.on = this.BMap.Overlay.prototype.addEventListener
              this.BMap.Overlay.prototype.off = this.BMap.Overlay.prototype.removeEventListener
              this.BMap.Overlay.prototype.getExtData = function () { return this.extData }
              this.BMap.Overlay.prototype.setExtData = function (v) { this.extData = v }
              // Point属性
              this.BMap.Point.prototype.getLng = function () { return this.lng }
              this.BMap.Point.prototype.getLat = function () { return this.lat }


            }else if(defindMap === 'Google'){
              if (!window.google){
                await remoteLoad(this.$GOOGLE_URL_EN)
              }
              await remoteLoad('https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js')
              let maxLat = Math.atan(Math.sinh(Math.PI)) * 180 / Math.PI;
              let map = new window.google.maps.Map(this.$refs.container, {
                center: { lng: center[0],lat: center[1] },
                minZoom:3,
                maxZoom:22,
                zoom: zoom,
                mapTypeControlOptions: {
                  position: window.google.maps.ControlPosition.TOP_RIGHT
                },
                restriction: {
                  latLngBounds: {north: maxLat, south: -maxLat, west: -180, east: 180},
                  strictBounds: true
                },
              })
              this.map = map
              this.google = window.google
              let panorama = this.map.getStreetView();
              panorama.setOptions({
                addressControlOptions:{
                  position: window.google.maps.ControlPosition.RIGHT_CENTER
                },
                zoomControlOptions:{
                  position: window.google.maps.ControlPosition.RIGHT_CENTER
                },
              })
              this.Wrapper = GetWrapper(window.google)
            }else {
              // if (!window.AMap) {
              //   await remoteLoad(this.$AMAP_URL)
              // }
              // if (!window.AMapUI) {
              //   await remoteLoad('//webapi.amap.com/ui/1.0/main.js?v=1.0.11')
              // }
              await remoteLoad(this.$AMAP_URL)
              await remoteLoad('//webapi.amap.com/ui/1.0/main.js?v=1.0.11')
              if (!this.SimpleMaker) {
                await this.initSimpleMarker()
              }
              if (defindMap === 'QQmap') {
                var layer = new window.AMap.TileLayer({
                  getTileUrl(x, y, z) {
                    let ty = Math.pow(2, z) - 1 - y
                    return `//p3.map.gtimg.com/maptilesv2/${z}/${Math.floor(x/16.0)}/${Math.floor(ty/16.0)}/${x}_${ty}.png?version=20190101`
                  },
                  zIndex: 2
                });
              } else if (defindMap === 'Google') {
                var layer = new window.AMap.TileLayer({
                  getTileUrl: '//47.88.25.10:10808/vt/lyrs=m@142&hl=zh-CN&gl=cn&src=app&x=[x]&y=[y]&z=[z]&s=Ga',
                  zIndex: 2
                });
              } else if (defindMap === 'Openstreet') {
                var layer = new window.AMap.TileLayer({
                  getTileUrl: '//a.tile.openstreetmap.org/[z]/[x]/[y].png',
                  zIndex: 2
                });
              } else if (defindMap === 'Bing') {
                var layer = new window.AMap.TileLayer({
                  getTileUrl: (x, y, z) => {
                    let code = new Array(z)
                    let nex = Math.pow(2, z - 1)
                    for (let i = 0; i < code.length; i++) {
                      let ox = Math.floor(x / nex) % 2
                      let oy = (Math.floor(y / nex) % 2) * 2
                      code[i] = ox + oy
                      nex = nex / 2
                    }
                    let imgNumber = code.join('')
                    return `https://t1.dynamic.tiles.ditu.live.com/comp/ch/${imgNumber}?mkt=${this.$i18n.locale=="zh" ? "zh-CN": "en-US"}&ur=CN&it=G,TW,L&n=z&og=5&cstl=rd`
                  },
                  zIndex: 2
                });
              } else {
                console.log('错误')
              }
              let map = new window.AMap.Map(this.$refs.container, {
                resizeEnable: true,
                zoom,
                center,
                isHotspot: false,
                features: [],
                preloadMode: false,
                zooms: [3, 21],
                layers: [layer],
                expandZoomRange: false
              })
              this.map = map
              this.AMap = window.AMap
              this.AMapUI = window.AMapUI
              this.ifNotExistsWindow()
              await this.initLayer()
              // await this.initRealScene()
            }
           resolve();
        });
      },
    initSimpleMarker() {
      const promise = new Promise((resolve) => {
        window.AMapUI.loadUI(['overlay/SimpleMarker'], SimpleMaker => {
          this.SimpleMaker = SimpleMaker
          resolve()
        })
      })
      return promise
    },
    ifNotExistsWindow() {
      if (!this.infoWindow) {
        let infoWindow = new this.AMap.InfoWindow({
          isCustom: true,
          content: '',
          offset: new this.AMap.Pixel(0, -42)
        })
        infoWindow.on('close', () => {
          this.isTrackInfoOpen = false
        })
        this.infoWindow = infoWindow
      }
    },
    initTabMap(center = [116.397428, 39.90923], zoom = 11) {
      if (this.tabMap) {
        return
      }
      const position = this.loadPosition() // mixin
      center = position.center
      zoom = position.zoom
       let defindMap = this.currentMap
      if (defindMap === 'Bmap'){
        let map =  new window.BMap.Map(this.$refs.tabContainer, { enableMapClick: false })
        map.centerAndZoom(new window.BMap.Point(center[0], center[1]), zoom)
        map.enableScrollWheelZoom(true)
        map.enableContinuousZoom()
        map.clearHotspots()
        this.tabMap = map
      }else if(defindMap === 'Google'){
        let map = new window.google.maps.Map(this.$refs.tabContainer, {
          center: { lng: center[0],lat: center[1] },
          minZoom:3,
          maxZoom:22,
          zoom: zoom
        })
        this.tabMap = map
      } else{
        let layer = null
        if (defindMap === 'QQmap') {
          layer = new window.AMap.TileLayer({
            getTileUrl(x, y, z) {
              let ty = Math.pow(2, z) - 1 - y
              return `//p3.map.gtimg.com/maptilesv2/${z}/${Math.floor(x/16.0)}/${Math.floor(ty/16.0)}/${x}_${ty}.png?version=20190101`
            },
            zIndex: 2
          })
          let map = new window.AMap.Map(this.$refs.tabContainer, {
            resizeEnable: true,
            zoom:zoom,
            center:center,
            isHotspot: false,
            features: [],
            preloadMode: false,
            zooms: [3, 21],
            layers: [layer],
            expandZoomRange: false
          })
          this.tabMap = map
        } else if (defindMap === 'Google') {
          layer = new window.AMap.TileLayer({
            getTileUrl: '//47.88.25.10:10808/vt/lyrs=m@142&hl=zh-CN&gl=cn&src=app&x=[x]&y=[y]&z=[z]&s=Ga',
            zIndex: 2
          });
          let map = new window.AMap.Map(this.$refs.tabContainer, {
            resizeEnable: true,
            zoom:zoom,
            center:center,
            isHotspot: false,
            features: [],
            preloadMode: false,
            zooms: [3, 21],
            layers: [layer],
            expandZoomRange: false
          })
          this.tabMap = map
        } else if (defindMap === 'Openstreet') {
          layer = new window.AMap.TileLayer({
            getTileUrl: '//a.tile.openstreetmap.org/[z]/[x]/[y].png',
            zIndex: 2
          })
          let map = new window.AMap.Map(this.$refs.tabContainer, {
            resizeEnable: true,
            zoom:zoom,
            center:center,
            isHotspot: false,
            features: [],
            preloadMode: false,
            zooms: [3, 21],
            layers: [layer],
            expandZoomRange: false
          })
          this.tabMap = map
        } else if (defindMap === 'Bing') {
          layer = new window.AMap.TileLayer({
            getTileUrl: (x, y, z) => {
              let code = new Array(z)
              let nex = Math.pow(2, z - 1)
              for (let i = 0; i < code.length; i++) {
                let ox = Math.floor(x / nex) % 2
                let oy = (Math.floor(y / nex) % 2) * 2
                code[i] = ox + oy
                nex = nex / 2
              }
              let imgNumber = code.join('')
              return `https://t1.dynamic.tiles.ditu.live.com/comp/ch/${imgNumber}?mkt=${this.$i18n.locale=="zh" ? "zh-CN": "en-US"}&ur=CN&it=G,TW,L&n=z&og=5&cstl=rd`
            },
            zIndex: 2
          })
          let map = new window.AMap.Map(this.$refs.tabContainer, {
            resizeEnable: true,
            zoom:zoom,
            center:center,
            isHotspot: false,
            features: [],
            preloadMode: false,
            zooms: [3, 21],
            layers: [layer],
            expandZoomRange: false
          })
          this.tabMap = map
        } else {
          let map = new window.AMap.Map(this.$refs.tabContainer, {
            resizeEnable: true,
            zoom,
            center
          })
          this.tabMap = map
        }
        
      }
    },
    // handleSelectRegion(item){
    //   this.rowClick(item)
    // },
    // querySearchAsync(queryString, cb){
    //   if(!this.queryKey||this.queryKey===''){
    //     return
    //   }
    //    API.GetRegionList(this.containChild, this.queryKey).then(ret => {
    //       this.table.pageIndex = 1
    //       let regionList = ret.data
    //       regionList.forEach(element => {
    //         element.value = element.PolygonName
    //       });
    //       cb(regionList)
    //     }).catch(err => {
    //   })
    // },
    clearSearch(){
      this.searchCheck ='';
      this.searchState = '';
    },
    handleQuery(val){
      this.searchCheck = val;
      this.treeCheckedKeys = [];
    },
    fetchRegionList() {
      const promise = new Promise((resolve, reject) => {
        API.GetRegionList(this.containChild, this.queryKey).then(ret => {
          this.table.pageIndex = 1
          this.regionList = ret.data
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
      return promise
    },
    fetchObjectList() {
      const promise = new Promise((resolve) => {
        GetObjectIdByMixID(this.treeCheckedKeys, null).then(({data}) => {
          resolve(data)
        })
      })
      return promise
    },
    fetchRegionObjectList() {
      if (!this.dialogListRegionId) {
        this.dialogList = []
        return
      }

      const promise = new Promise((resolve) => {
        API.GetRegionObject(this.dialogListRegionId).then(({data}) => {
          this.dialogList = data.slice()
          resolve()
        })
      })
      return promise
    },
    rowClick(row) {
      this.activeRegionId = row.PolygonID
      this.addRegionToMap(row)
      this.addBindObject(row)
    },
    addRegionToMap(row, map) {
      map = map || this.map
      this.removeRegionFromMap()
      if (window.BMap){
        let polygon = new this.BMap.Polyline(row.Points.map(j => new this.BMap.Point(j.Lon, j.Lat)),{
          strokeColor:"#1890ff", 
          strokeWeight:3, 
          strokeOpacity:0.9
        })
        this.polygon = polygon
        this.polygon.setMap(map)
        map.setViewport(row.Points.map(j => new this.BMap.Point(j.Lon, j.Lat)))
      }else if(window.google){
        let polygon = new this.google.maps.Polyline({
          map: map,
          path: row.Points.map(j => ({ lng: j.Lon, lat: j.Lat })),
          strokeColor:"#1890ff", 
          strokeWeight:3, 
          strokeOpacity:0.9,
          extID: row.RegionID
        })
        this.polygon = polygon
        if (map == this.tabMap) {
          this.tabPolygon = polygon
          this.setFitViewGoogle(this.tabMap, row.Points.map(j => ({ lng: j.Lon, lat: j.Lat })))
        }
        this.setFitViewGoogle(this.map, row.Points.map(j => ({ lng: j.Lon, lat: j.Lat })))

      }else{
         let polygon = new this.AMap.Polyline({
          map,
          path: row.Points.map(j => [j.Lon, j.Lat]),
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1'
        })
        this.polygon = polygon
        map.setFitView()
      }
    },
    removeRegionFromMap() {
      this.polygon && this.polygon.setMap(null)
    },
    modifyRegion(row) {
      this.modifyRow = row
      this.dialogVisible = true
      this.form.regionId = row.PolygonID
      this.form.RoadWidth = row.RoadWidth
      this.form.LimitSpeed = row.LimitSpeed
      this.form.timeRange = [miment('2018/01/01 ' + row.StartTime), miment('2018/01/01 ' + row.EndTime)]
      this.form.checkSpeed = row.LimitSpeed > 0
    },
    addRegion() {
      this.modifyRow = null
      this.dialogVisible = true
      this.isEditing = false
      this.isDrawEnd = false
      this.isMinimize = false
    },
    async deleteRegion(row) {
      try {
        await this.$confirm(this.$t('regionManage.deleteTip', {name: row.PolygonName}), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        })
      } catch (err) {
        return
      }

      API.DeleteRegion(row.PolygonID).then(ret => {
        if (!ret.errCode) {
          this.$message({
            message: this.$t('regionManage.deleteSuccessed'),
            type: 'success'
          })
          deleteFromArray(this.regionList, k => {
            return k.PolygonID === row.PolygonID
          })
        } else {
          this.$message({
            message: ret.errMsg,
            type: 'error'
          })
        }
      })
    },
    openRegionObject(row) {
      this.activeName = 'second'
      this.modifyRow = row
      this.dialogVisible = true
      this.form.regionId = row.PolygonID
      this.searchCheck =''
      this.searchState = ''
    },
    saveRoad() {
      let para = {
        RoadWidth: this.form.RoadWidth,
        LimitSpeed: this.form.LimitSpeed,
        StartTime: miment(this.form.timeRange[0]).format('hh:mm:ss'),
        EndTime: miment(this.form.timeRange[1]).format('hh:mm:ss')
      }

      if (!this.form.checkSpeed) {
        para.LimitSpeed = 0
      }

      return API.SaveRoad2(this.form.regionId, para)
    },
    async addRegionObject() {

      this.btnLoading = true
      let list = [];
      if(this.searchCheck){
        list.push(this.searchCheck.ObjectID)
      }else{
        if (!this.form.regionId) {
          this.$alert(this.$t('regionManage.selectTip2'))
          return
        }
        list = await this.fetchObjectList()
      }
      await this.saveRoad()
      
      API.AddRegionObject(this.form.regionId, list, this.form.IOType, this.form.needAlarm ? 1 : 0, this.form.checkSpeed ? 1 : 0, this.form.maxSpeed, this.form.timeRange[0], this.form.timeRange[1], this.form.inTips, this.form.outTips).then(({data}) => {
        if (!data.errCode) {
          this.$message({
            message: this.$t('regionManage.saveSuccessed'),
            type: 'success'
          })
          this.dialogVisible = false
          this.fetchRegionList()
        } else {
          console.log(data.errMsg)
        }
      }).catch(err => {
        console.error(err)
        this.btnLoading = false
      })
    },
    async openDialogList(row) {
      this.dialogListRegionId = row.PolygonID
      await this.fetchRegionObjectList()
      this.dialogListVisible = true
    },
    deleteRegionObject(regionId, objectid) {
      const list = [objectid]
      API.DeleteRegionObject(regionId, list).then(ret => {
        if (!ret.data.errCode) {
          const index = this.dialogList.findIndex(k => k.ObjectID === objectid)
          if (index != -1) {
            this.dialogList.splice(index, 1)
          }
        }
      })
    },
    tabClick() {

    },
    modifyDraw() {
      this.isMinimize = true
      this.isDrawEnd = false
      this.editObj(this.polygon, this.tabMap)
    },
    editObj(obj, map) {
      if(window.BMap && (this.map instanceof window.BMap.Map)){
         this.polygon.enableEditing();
        //  this.isDrawEnd = true
         this.isEditing = true
      }else if(window.google){
        this.tabPolygon && this.tabPolygon.setEditable(true)
        this.isEditing = true
      }else{
        window.AMap.plugin(['AMap.PolyEditor'], () => {
          let msTool = new window.AMap.PolyEditor(map, obj)
          this.isEditing = true
          msTool.open()
          msTool.on('end', ({target}) => {
            this.points = target.getPath()
            this.polygon = target
          })
          this.msTool = msTool
        })
      }
    },
    endEdit() {
      if(window.BMap && (this.map instanceof window.BMap.Map)){
         this.polygon.disableEditing();
         this.isEditing = false
         this.isDrawEnd = true
      }else if(window.google){
        this.isEditing = false
        this.isDrawEnd = true
        const points = this.tabPolygon.getPath().getArray().map(k => ({lng: k.lng(), lat: k.lat()}))
        this.points = points
        this.tabPolygon.setEditable(false)
      }else{
        this.isEditing = false
        this.isDrawEnd = true
        this.msTool.close()
        this.msTool = null
      }
    },
    resetDialog() {
      this.activeName = 'first'
      this.modifyRow = null
      this.btnLoading = false
      if(window.google){
        this.tabPolygon.setMap(null)
      }else {
        this.tabMap.clearMap()
      }
    },
    startDraw() {
      this.isMinimize = true
      this.isDrawEnd = false
      if(window.BMap && (this.map instanceof window.BMap.Map)){
        this.tabMap.clearMap()
        let styleOptions = {
            strokeColor: '#1890ff',
            strokeOpacity: '0.9',
            strokeWeight: 2,       //边线的宽度，以像素为单位。
            fillColor: '#1890ff',
            fillOpacity: '0.1'
        }
        let mousetool = new window.BMapLib.DrawingManager(this.tabMap, {
            isOpen: false, //是否开启绘制模式
            enableDrawingTool: true, //是否显示工具栏
            drawingToolOptions: {
                anchor: BMAP_ANCHOR_TOP_RIGHT, //位置
                offset: new BMap.Size(5, 5), //偏离值
                scale: 0.8 //工具栏缩放比例
            },
            polylineOptions: styleOptions, //线的样式
          })
          mousetool.setDrawingMode('polyline')
          mousetool.open()
          this.isDrawEnd = true
          mousetool.addEventListener('overlaycomplete', async e => {
            let obj = e.overlay
            this.polygon= e.overlay
            mousetool.close()
          })
          this.mousetool = mousetool
      }else if(window.google){
        this.tabPolygon && this.tabPolygon.setMap(null)
        if (this.mousetool) {
          this.mousetool.setMap(this.tabMap)
          this.mousetool.setDrawingMode('polyline')
          this.mousetool.setDrawingControl = false
          return
        }
        this.mousetool = new this.google.maps.drawing.DrawingManager({
          drawingControl : false,
          drawingControlOptions: {},
          drawingMode: 'polyline',
          map: this.tabMap,
          polylineOptions: {
            strokeColor: '#1890ff',
            strokeOpacity: '0.9',
            fillColor: '#1890ff',
            fillOpacity: '0.1'
          }
        })
        this.google.maps.event.addListener(this.mousetool, 'polylinecomplete',async target => {
          this.isDrawEnd = true
          console.log(target)
          const points = target.getPath().getArray().map(k => ({lng: k.lng(), lat: k.lat()}))
          console.log(points)
          this.points = points
          this.mousetool.setDrawingMode(null)
          this.tabPolygon = target
        })

      }else{
        this.tabMap.clearMap()
        if (this.mousetool) {
          this.mousetool.polyline({
            strokeColor: '#1890ff',
            strokeOpacity: '0.9',
            fillColor: '#1890ff',
            fillOpacity: '0.1'
          })
          return
        }

        this.tabMap.plugin(['AMap.MouseTool'], () => {
          let mousetool = new this.AMap.MouseTool(this.tabMap)
          mousetool.polyline({
            strokeColor: '#1890ff',
            strokeOpacity: '0.9',
            fillColor: '#1890ff',
            fillOpacity: '0.1'
          })
          mousetool.on('draw', ({obj}) => {
            this.isDrawEnd = true
            const points = obj.getPath()
            this.points = points
            this.polygon = obj
            mousetool.close()
          })
          this.mousetool = mousetool
        })
      }
    },
    async saveAndNext() {
      try {
        const result = await this.$prompt(this.$t('regionManage.enterRegionTip'), '', {
          inputValue: (this.modifyRow && this.modifyRow.PolygonName) || '',
          confirmButtonText: this.$t('common.save'),
          cancelButtonText: this.$t('common.cancel'),
        })
        this.btnLoading = true
        let list = []
        if(window.BMap && (this.map instanceof window.BMap.Map)){
          let path = this.polygon.getPath()
          let points = []
          if (path instanceof Array) {
            for (let i = 0; i < path.length; i++) {
              points = points.concat(path[i])
              this.points = points
            }
          }
          list = (await ConvertFrom(points,'bd', 'mars')).data

        }else{
          list = (await ConvertFrom(this.points,'marsr','gps')).data
        }
        
        const regionId = this.modifyRow && this.modifyRow.PolygonID
        let para = null
        if (this.modifyRow) {
          para = {
            RoadName: result.value,
            RoadWidth: this.modifyRow.RoadWidth,
            LimitSpeed: this.modifyRow.LimitSpeed,
            StartTime: this.modifyRow.StartTime,
            EndTime: this.modifyRow.EndTime
          }
        } else {
          para = {
            RoadName: result.value,
            RoadWidth: 10,
            LimitSpeed: 0,
            StartTime: '00:00:00',
            EndTime: '23:59:59'
          }
        }

        API.SaveRegion(this.modifyRow ? this.modifyRow.PolygonID : 0, para, list.map(k => { return { Lon: k.lng, Lat: k.lat }})).then(ret => {
          console.log(ret)
          this.form.checkSpeed = para.LimitSpeed > 0
          this.form.maxSpeed = para.LimitSpeed
          this.form.RoadWidth = para.RoadWidth
          this.form.timeRange = [miment('2018/01/01 ' + para.StartTime), miment('2018/01/01 ' + para.EndTime)]
          this.form.name = result.value
          if (ret.data.data.RoadID) {
            this.activeName = 'second'

            if (regionId) {
              const index = this.regionList.findIndex(k => k.PolygonID === regionId)
              console.log('索引',index)
              if (index != -1) {
                const row = this.regionList[index]
                row.PolygonName = result.value
                row.Points = this.points.map(k => {
                  return {
                    RegionID: regionId,
                    PointID: -1,
                    Lat: k.lat,
                    Lon: k.lng
                  }
                })
                this.regionList.splice(index, 1, row)
              }
            } else {
              this.fetchRegionList()
            }

            this.btnLoading = false
          }

          this.form.regionId = ret.data.data.RoadID
        })
      } catch (err) {
        console.error(err)
        // this.$message({
        //   message: '服务器又开小差了',
        //   type: 'error'
        // })
      }
    },
    treeCheck(keys) {
      this.treeCheckedKeys = keys.slice()
    },

    addBindObject(row) {
      this.activePolygon.PolygonName = row.PolygonName
      this.activePolygon.PolygonID = row.PolygonID
      API.GetRegionObject(row.PolygonID).then(({data}) => {
        this.activePolygon.list = data.slice()
        this.showBindObject = true
      })
    },
    refreshBindObject() {
      if (!this.activePolygon.PolygonID) {
        return
      }
      API.GetRegionObject(this.activePolygon.PolygonID).then(({data}) => {
        this.activePolygon.list = data.slice()
        this.showBindObject = true
      })
    },
    setFitView(map, markers) {
      let points = []
      if (markers instanceof Array) {
        for (let i = 0; i < markers.length; i++) {
          let k = markers[i]
          let pos = k.getPath()
          points = points.concat(pos)
        }
      }
      if (points.length) {
        map.setViewport(points)
      }
    },
    setFitViewGoogle(map, markers) {
      // const points = markers.map(k => k.getPosition())
      // const minLon = Math.min(...points.map(k => k.lng()))
      // const maxLon = Math.max(...points.map(k => k.lng()))
      // const minLat = Math.min(...points.map(k => k.lat()))
      // const maxLat = Math.max(...points.map(k => k.lat()))
      // const bounds = new window.google.maps.LatLngBounds(new window.google.maps.LatLng(minLat, minLon), new window.google.maps.LatLng(maxLat, maxLon))
      const bounds = new window.google.maps.LatLngBounds()

      if (markers instanceof Array && markers && markers.length > 0) {
        var k = markers[0]
        if (k instanceof window.google.maps.Marker) {
          const points = markers.map(k => k.getPosition())

          for (let i = 0; i < points.length; i++) {
            bounds.extend(points[i])
          }
        } else if (k instanceof window.google.maps.LatLng) {
          for (let i = 0; i < markers.length; i++) {
            bounds.extend(markers[i])
          }
        } else if (k.lng && k.lat) {
          for (let i = 0; i < markers.length; i++) {
            bounds.extend(markers[i])
          }
        }

          map.fitBounds(bounds)
      } else {
        console.error('error occured in setFitView, invlaid input')
      }
    },
    carSelect(lon, lat, name) {
      if (!lon) {
        this.$message(this.$t('onlineMonitor.noPositionTip'))
        return
      }
      if (this.carMarker) {
        this.carMarker.setMap(null)
        this.carMarker = null
      }

      if(this.google){
        this.marker =[]
         this.map.setCenter(new this.google.maps.LatLng(lat, lon))
        // 切换地图zoom
        if (this.map.getZoom() < 15) {
          this.map.setZoom(15)
        }
        this.carMarker = new this.google.maps.Marker({
          position: { lng:lon, lat: lat },
          map: this.map,
          title: name
        })
        this.marker =[lon, lat]
      }else if(this.BMap){
        if(this.carMarker){
          this.carMarker.setMap(null)
        }
        let obj = {
          lng:lon,
          lat:lat
        }
        ConvertFrom([obj], 'mars', 'bd').then(ret => {
          lon = ret.data[0].lng
          lat = ret.data[0].lat
          this.carMarker = new this.BMap.Marker(new this.BMap.Point(lon, lat))
          this.carMarker.setMap(this.map)
          this.map.centerAndZoom(new this.BMap.Point(lon, lat),18)
        })  
        
      }else{
        this.map.setCenter([lon, lat])
        this.map.setZoom(16)
        this.carMarker = new window.AMap.Marker({
          position: [lon, lat],
          map: this.map,
          title: name
        })
      }
      
    },
    carSelectTab(lon, lat, name) {
      if (!lon) {
        this.$message(this.$t('onlineMonitor.noPositionTip'))
        return
      }
      if (this.carMarker) {
        this.carMarker.setMap(null)
        this.carMarker = null
      }
      if(this.google){
         this.marker =[]
        this.tabMap.setCenter(new this.google.maps.LatLng(lat, lon))
          // 切换地图zoom
        if (this.tabMap.getZoom() < 15) {
          this.tabMap.setZoom(15)
        }
  
        this.carMarker = new this.google.maps.Marker({
          position: { lng:lon, lat: lat },
          map: this.tabMap,
          title: name
        })
        this.marker =[lon, lat]
      }else if(this.BMap){
        if(this.carMarker){
          this.carMarker.setMap(null)
        }
         let obj = {
          lng:lon,
          lat:lat
        }
        ConvertFrom([obj], 'mars', 'bd').then(ret => {
          lon = ret.data[0].lng
          lat = ret.data[0].lat
          this.carMarker = new this.BMap.Marker(new this.BMap.Point(lon, lat))
          this.carMarker.setMap(this.tabMap)
          this.map.centerAndZoom(new this.BMap.Point(lon, lat),18)
        })  
      }else{
        this.tabMap.setCenter([lon, lat])
        this.tabMap.setZoom(16)
        this.carMarker = new window.AMap.Marker({
          position: [lon, lat],
          map: this.tabMap,
          title: name
        })
      }
    }
  },
  mixins: [
    mixinMapCachePosition
  ]
}
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';
@import "../../styles/variables-simple.scss";
@include v-wrapper;
@include v-area;
@include v-item;
.v-nav {
  @include scroll-bar-grey;
}
.v-nav-road{
  width:250px;
}
.region {
  font-size: 12px;
  padding: 8px 12px 8px 20px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
  // padding: 0 12px 0 0;
  border: 1px solid #DCDFE6;
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: $--color-primary-light-9;
  }
  &.active {
    border: 1px solid $--color-primary;
    background-color: $--color-primary-light-9;
  }

  .content {
    flex-grow: 1;
    .region__name {
      //margin-bottom: 0.5rem;
      font-size: 14px;
      color: #606266;
      line-height: 22px;
    }
    .region__desc {
      color: #909399;
    }
  }
  .option {
    width: 30px;
    .i-dropdown {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.tab-wrapper {
  height: 500px;
  .tab-place-search {
    position: absolute;
    z-index: 111;
    top: 50px;
    left: 20px;
    width: 350px;
    box-shadow: 0 2px 6px 0 rgba(114,124,245,.5);
    background-color: #FFF;
  }

  .v-tool {
    position: absolute;
    z-index: 111;
    top: 25%;
    left: 25%;
    height: 50%;
    width: 50%;
    transition: all 0.3s ease;
    background-color: rgba(64,158,255,.8);
    border-radius: 4px;
    .tip {
      color: #FAFAFA;
      height: 38px;
      line-height: 38px;
      padding-left: 12px;
    }
    .start {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 12px 24px;
      font-size: 18px;
      color: #FFF;
      transition: all 0.3s ease;
      cursor: default;
      & span:hover {
        // color: #ef5350;
        cursor: pointer;
        transition: all 0.3s ease;
        display: inline-block;
        transform: translateY(-5px);
      }
    }

    &.minimize {
      top: 0;
      left: 0;
      height: 38px;
      width: 100%;
      .start {
        display: none;
      }
    }
  }
  &.tab--flex {
    display: flex;

    .tab-nav {
      width: 250px;
      border-left: 1px solid #E4E7ED;
      &.en {
        width: 350px;

        .v-item {
          .v_title {
            width: 11em;
            text-align: right;
          }
          .v_text {
            margin-left: 11em;
          }
        }
      }
    }
    .tab-body {
      flex-grow: 1;
    }
  }
  .v-item {
    .v_title {
      line-height: 32px;
      height: 32px;
    }
  }

  & ::v-deep .amap-logo,& ::v-deep .amap-copyright {
    display: none !important;
    z-index: -1;
  }
}
.flat-btn {
  float:right;
  line-height:38px;
  height:38px;
  padding-top:0;
  padding-bottom:0;
  // background-color: #FF5722;
  // border: 0;
  // color: #FFF;
}
.v-number {
  width: 40px !important;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
  & ::v-deep .el-input .el-input__inner {
    padding: 0 6px;
  }
}
.v-range {
  padding: 3px 10px;
  & ::v-deep .el-icon-time {
    display: none;
  }
  & ::v-deep .el-range__close-icon {
    display: none;
  }
  & ::v-deep .el-range-input {
    width: 63%;
  }
}
.v-pager {
  & ::v-deep .btn-next {
    float: right;
  }
}
.fixedTop {
  position: absolute;
  width: 350px;
  border-radius: 4px;
  padding: 12px 24px 24px;
  top: 24px;
  right: 24px;
  background-color: rgba(255, 255, 255, .9);
  border: 1px solid rgba(0, 0, 0, .24);
  .fixedTopHeader {
    height: 32px;
    line-height: 32px;
    color: rgba(0, 0, 0, .86);
    font-size: 16px;
    .fixedTopHeaderClose {
      float: right;
      i {
        font-size: 16px;
        &:hover {
          color: rgba(0, 0, 0, 1);
          cursor: pointer;
        }
      }
      i + i {
        margin-left: 12px;
      }
    }
  }
  .vehicle-split {
    margin: 4px auto 12px;
    height: 1px;
    background-color: rgba(0, 0, 0, .24);
    width: 302px;
  }
  ul {
    list-style-position: inside;
    margin-bottom: -8px;
    li {
      font-size: 14px;
      margin-bottom: 8px;
      .IOType {
        color: $--color-primary;
        float: right;
        font-size: 12px;
      }
      .SIM2 {
        margin: 4px 0 0 20px;
        color: rgba(0, 0, 0, .56);
        .Speed {
          font-size: 12px;
        }
      }
    }
  }
  .nodata {
    height: 40px;
    line-height: 40px;
    color: rgba(0, 0, 0, .54);
    font-size: 14px;
    text-align: center;
    margin-top: 24px;
  }
  .viewAll {
    font-size: 14px;
    margin: 16px 0 0 20px;
    color: $--color-primary;
    cursor: pointer;
  }
}
/*fade*/
.fade-polygon-enter-active,
.fade-polygon-leave-active {
  transition: all 0.28s ease-out;
}

.fade-polygon-enter,
.fade-polygon-leave-active {
  opacity: 0;
  transform: translateY(30px);
}

.risk-polygon-query ::v-deep input{
  border-radius: 20px;
  transition: all .3s ease;
  &:focus {
    background-color: #F4F4F8;
    box-shadow: 0 1px 3px 0 rgba(44, 34, 243, 0.25);
    border-color: transparent;
  }
}
.searchInput{
  text-align: center;
  padding:0 15px;
  .inner{
    width:84%;
    margin-bottom: 5px;
  }
  .group{
    width:96%;
    margin:10px auto;
    text-align: left;
    .tag{

    }
  }
}
.tabPanels{
    width: 100%;
    // height: 560px;
    display: flex;
    .cols{
      flex-grow: 1;
       overflow: hidden;
    }
    .step1{
      width:50%;
      border: 1px solid #e0e1e1;
      border-radius: 10px;
       & ::v-deep .treeBlock{
        border-right:0;
      }
    }
    .step2{
      padding-left:20px;
    }

    .con {
      width: 100%;
      height: 100%;
    }

    .selectUser {
        border: 1px solid #e0e1e1;
        padding: 5px;
        border-radius: 10px;

        .searchBar {
          margin-bottom: 15px;
        }
    }

    .solid {
        padding: 5px 10px;
    }
    .label-form{
      color: #999;
      margin-right: 10px;
    }

    .bot {
        border: 1px dashed #1890ff;
        border-radius: 5px;
        padding: 5px 10px;

        .tips {
            margin-top: 10px;
            height: 20px;
            color:#1890ff;
        }
        .clk{
            cursor: pointer;
            line-height: 28px;
            text-indent: 10px;
            color:#1890ff;
        }
    }
    .elRow{
      display: flex;
      .elCol{
        margin-right: 15px;
      }
    }

    .line {
        display: flex;
        span {
            width: 40px;
            position: relative;
            z-index: 1;

            i {
              position: absolute;
              left: 10px;
              top: 6px;
              z-index: 1;
              display: block;
              width: 18px;
              height: 18px;
              border: 1px solid #ccc;
              border-radius: 50%;
            }

            .icon {
              width: 26px;
              height: 26px;
              color: #1890ff;
              position: absolute;
              left: 6px;
              top: 3px;
              z-index: 1;
            }
        }

        p {
            width: 100px;
        }
    }
    & ::v-deep .el-form-item--small.el-form-item{
      margin-bottom: 0px;
    }
    & ::v-deep .el-form-item__label{
      padding-bottom: 0;
    }
}
</style>

